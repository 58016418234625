.userList {
    flex: 4;
    padding-left: 20px;
    padding-right: 20px;
}
.userListUser{
    display: flex;
    align-items: center;    
    font-weight: bold;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
.userListDelete{
    color: red;
    cursor: pointer;
}
