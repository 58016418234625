.featured{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.featuredItem{
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px 0px #cac0c0; 
    box-shadow: 0px 0px 10px 0px #beb3b3;
}

.featuredTitle{
    font-size: 20px;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredMoney{
    font-size: 30px;
    font-weight: bold;
}
.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon {
    font-size: 14px;
    margin-left: 5px;
    color:  green;
}
.featuredIcon.negative{
    color: red
}