.container{
    display: flex;
    margin-top: 10px;
}

.others{
    flex: 4;
    background-color: darkcyan;
}

.link{
    text-decoration: none;
    color: inherit;
}