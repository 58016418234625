.newUser{
    flex: 4;
}
.newUserTitle{

}

.newUserForm{
    display: flex;
    flex-wrap: wrap;
}

.newUserItem{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}
.newUserItem>label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(153, 68, 68);
}
.newUserItem>input{
    height: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.newUserGender>input{
    margin-top: 10px;    
}
.newUserGender>label{
    margin: 10px;
    font-size: 19px;
    color: #555;
}