.newArtistContainer{    
    flex: 4;
    padding-left: 20px;
    padding-right: 20px;
}

.rowContainer{
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: baseline;
    display: flex;
    justify-content: space-between;    
    flex-direction: row; 

}