.cw-datepicker-container {
    height: 83px;
    align-items: flex-start;
}

.cw-datepicker-container .label {
  color: #353957;
  font-family: "Google Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.cw-datepicker-container .label_disabled {
  color: #9EA0A5;
  font-family: "Google Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.cw-datepicker-container .error {
  display: 'flex';
  font-size: 14px;
  color: #F06775;
  margin-top: 4px;
  font-family: 'Google Sans';
}

.cw-datepicker-container .info {
  display: 'flex';
  font-size: 14px;
  color: #9EA0A5;
  margin-top: 4px;
  font-family: 'Google Sans';
}

.react-datepicker-wrapper {
  align-self: stretch;
  display: flex;
  position: relative;
  flex: 1;
  height: 40px;
}
.react-datepicker__input-container input{
  flex: 1;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 16px;
  margin-top: 4px;
  display: flex;
  align-self: stretch;
  border: 1px solid #A7A6C5;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: black;
  font-family: "Google Sans Light";
  font-size: 14px;
  line-height: 22px;
}

.react-date-picker {
  align-self: stretch;
  display: flex;
  position: relative;
  flex: 1;
  height: 40px;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex: 1;
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 16px;
  margin-top: 4px;
  display: flex;
  align-self: stretch;
  border: 1px solid #A7A6C5;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: black;
  font-family: "Google Sans Light";
  font-size: 14px;
  line-height: 22px;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}
