.cw-label-container {
    align-items: flex-start;
}
.cw-label-container .label {
    display: 'flex';
    color: black;
    font-family: "Google Sans";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    
}

.cw-label-container .field {
    color: #353957;
    font-family: "Google Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.page-title {
    color: black;
    font-family: "Google Sans";
    font-size: 20px;
}