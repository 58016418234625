
.festivalLists{
    flex: 4;
    padding-left:20px;    
    padding-right: 20px;
    height: '100%';
}

.headerContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    margin-bottom: 10px;

}

.importFestivalButton{
    margin-left: 10px;
    padding-right: 20px !important;
    padding-left: 20px !important;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;    
    cursor: pointer;
    color: white;
    font-size: 16px; 
}

.cw-modal-change-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.cw-modal-change-password-container .btn-close {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 24px;
    right: 24px;
    
}

.cw-modal-change-password-container .title {
    color: #353957;
    font-family: "Google Sans";
    font-size: 36px;
    font-weight: 500;
    line-height: 50px;
    text-align: center;
    margin-top: 80px;
}
