
.editCategory{
    flex:4;
    padding-left: 20px;
    padding-right: 20px;
}

.itemContainer {
    display: flex;    
    flex-direction: column;
    margin-top: 20px;
}

.itemContainer>label{
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(153, 68, 68);
}

.itemContainer>input{
    height: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 0px 0px;
}

.updateBtnContainer{
    margin-top: 20px;
}

.updateButton{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}
