.cw-imagepicker-container {
    display: flex;
    height: 187px;
    width: 320px;
    flex-direction: column;
}

.cw-imagepicker-container .error {
    display: 'flex';
    font-size: 14px;
    color: #F06775;
    margin-top: 4px;
    font-family: 'Google Sans';
}

.cw-imagepicker-container .label {
    color: #353957;
    font-family: "Google Sans";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }
  
  .cw-imagepicker-container .label_disabled {
    color: #9EA0A5;
    font-family: "Google Sans";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

.cw-imagepicker-container .image-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    border: 1px solid #A7A6C5;
    border-radius: 4px;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
}

.cw-imagepicker-container .image-container .preview{
    display: flex;
    position: absolute;
    top:0;
    left:0;
    height: 168px;
    width: 320px;
    border:none;
    outline: none;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.cw-imagepicker-container .image-container .preview img{
    display: flex;
    max-height: 168px;
    max-width: 320px;
}

.cw-imagepicker-container .image-container .description{
    margin-top: 4px;
    color: #A7A6C5;
    font-family: "Google Sans";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

