.addFestival{
    flex: 4;
    padding-left: 20px;
    padding-right: 20px;
}
.rowContainer2{
    width: 100%;
    display: flex;
    align-items: baseline;
    display: flex;
    justify-content: space-between;    
    flex-direction: row; 
    
}
.columnContainer{
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;   
    /* align-items: baseline;  */
}
.itemContainer{
    flex:1
}
.thubImgContainer{
    display: flex;
    justify-content: center;
}