.user{
    flex: 4;    
    padding: 10px;
}

.userTitleContainer{
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.userAddButton{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px; 
}

.userContainer{
    display: flex;
    padding: 15px;
}

.userShow{
    margin-left: 0px;
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 5px -1px #3b3131; 
    box-shadow: 0px 0px 5px -1px #3b3131;
    border-radius: 10px;
}

.userUpdate{
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 5px -1px #3b3131; 
    box-shadow: 0px 0px 5px -1px #3b3131;
    border-radius: 10px;
    margin-left: 20px;
}

.userShowTop{
    display: flex;
    align-items: center;
}
.userShowImage{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.userShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.userShowUserName{
    font-weight: 600;
    
}
.userShowUserTitle{
    font-weight: 300;
}

.userShowIcon{
    font-size: 16px !important;
}

.userShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: #ddd;
}
.userShowBottom{
    margin-top: 20px;
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color : #444;
}

.userShowInfoTitle{
    margin-left: 10px;
    font-weight: 600;
}
.userUpdateTitle{
    font-size: 24px;
}
.userUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20xp;
}
.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid gray;
}

.userUpdateRight{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userUpdateUpload{
    display: flex;
    align-items: center;    
}
.userUpdateIcon{
    cursor: pointer;    
}

.userUpdateImage{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;

}
.userUpdateButton{
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: darkblue;
    cursor: pointer;
    color: white;
    font-weight: bold;
}
